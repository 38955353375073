import React, { useCallback, useEffect, useState } from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialogue, DialogueChildren, Range } from '..';
import { sidebarMenus } from '../../utils/sidebarMenus';
import { Avatars } from '../Avatars';
import { StoryModal } from '../StoryModal';
import { LeftArrow, LogoutIcon, RightArrow } from '../SVGIcons';
import { Typography } from '../Typography';
import "./index.scss";
import { actionGetStory } from '../../actions/dashboard';
import { SnackMessages } from '../Toast';
import { actionLogout } from '../../actions/auth';

const Sidebar = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state?.authReducer);
    const { isLoadStory } = useSelector(state => state?.dashboard);
    const { showErrorMessage, showSuccessMessage } = SnackMessages();
    const navigate = useNavigate();
    const pageLocation = useLocation();
    const [rangeLoader, setRangeLoader] = useState(true)
    const [isClicked, setIsClicked] = useState(false);
    const [isCollapsed, setCollapsed] = useState(false)
    const [location, setLocation] = useState("")
    const [modalShow, setModalShow] = useState({
        isOpen: false,
        children: null,
        title: null,
        size: 'md'
    });
    const [logoutmodalShow, setLogoutmodalShow] = useState({
        title: '',
        subTitle: '',
        label: '',
        sublabel2: '',
        yesButtonText: 'Yes',
        noButtonText: '',
        isOpen: false,
        icon: '',
        buttonIcon: '',
        pos: '',
        description: '',
        buttonMargin: ''
    });

    const handleSelectMenuItems = (i) => {
        navigate(sidebarMenus[i]?.path);
        setLocation(pageLocation.pathname)
    }

    useEffect(() => {
        setLocation(pageLocation.pathname)
    }, [pageLocation])


    useEffect(() => {
        setTimeout(() => {

            setRangeLoader(false)
        }, 300);
    }, [rangeLoader])

    const getStories = useCallback(async () => {
        const res = await dispatch(actionGetStory())
        if (res?.success) {
            const newoffersandPromotions = (res?.data?.length && [...res?.data]?.map((offer, i) => ({
                images: [`${offer?.url}${offer?.image}`]
            }))?.reduce((accumulator, object) => {
                return [...accumulator, ...object?.images || []];
            }, [])) || [];
            setModalShow({ ...modalShow, isOpen: true, 
                children: <StoryModal handleCloseModal={() => setModalShow({ ...modalShow, isOpen: false })} loader={isLoadStory} options={newoffersandPromotions} />, title: "" });
        } else {
            showErrorMessage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, modalShow, showErrorMessage])

    const handleLogoClick = () => {
        if (pageLocation?.pathname === "/dashboard") {
            if (!isClicked) {
                getStories()
                setIsClicked(true);

                // Reset the click after a certain time
                setTimeout(() => {
                    setIsClicked(false);
                }, 2000);
            }
        }
        else navigate("/dashboard")

    }

    const loyalityAmount = (amt) => {
        const roundedNum = (amt / 3000000) * 100 //Converting to million
        const formattedNum = roundedNum.toFixed(2);
        return formattedNum
    }

    const getPoints = (amt) => {
        const roundedNum = (amt / 1000000) //Converting to million
        const formattedNum = roundedNum.toFixed(2);
        return formattedNum
    }

    const hanldeClose = () => {
        setModalShow({ ...modalShow, isOpen: false });
    }

    const yesButtonHandler = async () => {
        const logout = await dispatch(actionLogout());
        if (logout?.success) {
            showSuccessMessage(logout?.message);
            navigate('/auth/login');
            localStorage.removeItem('logged-in');
        }
        else showErrorMessage(logout?.message)
    }

    const noButtonhandler = () => setLogoutmodalShow({ ...logoutmodalShow, isOpen: false })

    const handleSideBarLogout = () => {
        setLogoutmodalShow({
            ...logoutmodalShow,
            title: "Are you sure  ",
            sublabel2: " you want to log out?",
            label: '',
            yesButtonText: "Yes, Logout",
            noButtonText: "No, Thanks!",
            buttonMargin: "mt-3",
            yesButtonHandler: (e) => yesButtonHandler(),
            noButtonhandler: (e) => noButtonhandler(),
            isOpen: true,
            yesButtonIcon: false,
            buttonIcon: null
        });
    }

    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <Dialogue
                modalShow={modalShow.isOpen}
                handleCloseModal={hanldeClose}
                size={modalShow.size}
                className="story-modal"
                children={modalShow.children} />
            <Dialogue
                modalShow={logoutmodalShow.isOpen}
                handleCloseModal={logoutmodalShow.noButtonhandler}
                children={<DialogueChildren content={{
                    title: logoutmodalShow.title,
                    subTitle: logoutmodalShow.subTitle,
                    label: logoutmodalShow.label,
                    sublabel2: logoutmodalShow.sublabel2,
                    yesButtonText: logoutmodalShow.yesButtonText,
                    buttonIcon: logoutmodalShow.buttonIcon,
                    icon: logoutmodalShow.icon,
                    noButtonText: logoutmodalShow.noButtonText,
                    buttonMargin: logoutmodalShow.buttonMargin,
                    yesButtonHandler: (data) => logoutmodalShow.yesButtonHandler(data),
                    noButtonhandler: (data) => logoutmodalShow.noButtonhandler(data),
                    pos: logoutmodalShow.pos,
                    description: logoutmodalShow.description,
                }} />}
            />
            <div className='logo-container'>
                <div className='logo-image' onClick={handleLogoClick}>
                    <Avatars src="/images/logo-sm.svg" />
                    <Typography variant="logo-title" label={"TAB SURFACES"} />
                </div>
                {isCollapsed ? <RightArrow onClick={() => setCollapsed(!isCollapsed)} /> : <LeftArrow onClick={() => setCollapsed(!isCollapsed)} />}
            </div>

            <ListGroup>
                {sidebarMenus?.length > 0 &&
                    sidebarMenus.map((menu, i) =>
                        <ListGroup.Item key={menu.id} onClick={() => handleSelectMenuItems(i)} active={location === menu.path} disabled={menu.disabled} className={menu.class}>
                            <div className='menu-icon'>
                                <menu.icon />
                            </div>
                            <div className='menu-item-text'>
                                {menu?.name}
                            </div>
                        </ListGroup.Item>)}
            </ListGroup>
            <div onClick={() => navigate("/profile")}>
                {!user?.loyality_amount ? <RangeLoader /> : user?.loyality_amount > 250000 ?
                    <Range variant="two-point-range"
                        label1={loyalityAmount(user?.loyality_amount) > 1 ? "Platinum" : loyalityAmount(user?.loyality_amount) > 0.5 ? "Gold" : loyalityAmount(user?.loyality_amount) > 0.25 ? "Silver" :  "Bronze" }
                        label2={loyalityAmount(user?.loyality_amount) > 1 ? "Diamond" : loyalityAmount(user?.loyality_amount) > 0.5 ? "Platinum" : loyalityAmount(user?.loyality_amount) > 0.25 ? "Gold" :   "Silver"}
                        value={[loyalityAmount(user?.loyality_amount) > 100 ? 100 : loyalityAmount(user?.loyality_amount)]} point={getPoints(user?.loyality_amount)} range={[0, 100]} /> : <><Typography variant={"body4"} label={` $${250000 - user?.loyality_amount} away from Silver membership`} /></>}
            </div>
            <div className='sidebar-logout d-flex gap-2 align-items-center justify-content-center my-4' onClick={handleSideBarLogout}>
                <span>Log out</span>
                <LogoutIcon />
            </div>
        </div>
    )
}
export default Sidebar;

const RangeLoader = () => {
    return (
        <div className='d-flex flex-column gap-1 justify-content-center m-auto'>
            <Placeholder animation="glow">
                <Placeholder style={{ width: 200, height: 36 }} />
            </Placeholder>
            <Placeholder animation="glow">
                <Placeholder style={{ width: 180, height: 48 }} />
            </Placeholder>
        </div>
    )
}