import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import "./index.scss"

export const InfiniteScroller = ({ children, dataLength, loadFunc, loader, endMessage, hasMore, scrollableTarget, className }) => {

    return (
        <div className='scroll-component flex-fill' >
            <InfiniteScroll
                height={400}
                dataLength={dataLength}
                next={loadFunc}
                hasMore={hasMore}
                scrollableTarget={scrollableTarget}
                loader={loader}
                className={`infinite-scroller d-flex   flex-fill ${className}`}
                endMessage={endMessage}>
                {children}
            </InfiniteScroll>
        </div>
    )
}
