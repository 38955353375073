import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import { Avatar } from "..";

export const CustomCarousal = ({ options, handleActiveIndex, variant, loader, handleClick, handleCloseModal, handleZoomImage, baseUrl }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const settings = {
    customPaging: (idx) => (<span className="image-options" id={`image${idx}`}>
      {options?.length > 0 &&
        <Avatar errorImg="/images/noimage.svg" src={options[idx]?.src} variant={'product-detail-carousal-options'} />
      }
        
    </span>),
    dots: true,
    dotsClass: "slick-dots slick-thumb justify-content-start", 
  infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_, next) => { setActiveIndex(next); handleActiveIndex(next) }

  };

  const offerPromotion = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 100,
    variableWidth: true,
    className: "slider variable-width",
    dotsClass: "slick-dots slick-thumb",
    arrows: true,
  }

  let timer = 0;
  const handlePlayNextModal = (_, next) => {
    if (timer) {
      clearTimeout(timer);
      timer = 0;
    }

    return next + 1 === options?.length ? handleCloseModal() : null
  }


  const storySettings = {
    dots: true,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    beforeChange: handlePlayNextModal,
  }

  const newsAndOffersLarge = {
    swipeToSlide: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 50,
    speed: 100000,
    cssEase: 'linear',
    variableWidth: true,
  };

  const newsAndOffersMedium = {
    swipeToSlide: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 50,
    speed: 50000,
    cssEase: 'linear',
    variableWidth: true,
  };

  const promotionSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slide: '> div',
  };


  const selectImage = (idx) => document.getElementById(`image${idx}`).click();

  const renderCarousal = () => {
    switch (variant) {
      case 'offer-and-promotion':
        return (
          <div className="offer-promotion-carousal">
            {!loader ? <Slider {...offerPromotion}>
              {options?.length > 0 &&
                options?.map((option, i) =>
                  <div key={option.id} className="active-images" onClick={() => handleClick(option)} >
                    <Avatar errorImg="/images/noimage.svg" src={option?.image} variant={'dash-newsoffer-image'} />
                  </div>)}
            </Slider> :
              <Slider {...offerPromotion}>
                {[1, 2, 3, 4, 5]?.map(e => <div key={e} className="active-images"  >
                  <Avatar key={e} errorImg="/images/noimage.svg" src={"/images/noimage.svg"} variant={'dash-newsoffer-image'} />
                </div>)}
              </Slider>}
          </div>
        )

      case "story-carousal":

        return (< div className="story-carousal" id="story-carousal">
          <Slider {...storySettings}>
            {!loader && options?.length > 0 &&
              options?.map((option, i) =>
                <div key={i} className="active-images" onClick={() => handleClick(option)} style={{ width: i % 3 === 0 ? 300 : 'auto' }}>
                  <Avatar errorImg="/images/noimage.svg" src={option} variant={'story-image'} />
                </div>)}
          </Slider>
        </div >)

      case "news-and-offers-large":
        return (< div className="news-and-offers-large" id="news-and-offers-large">
          <Slider {...newsAndOffersLarge}>
            {options?.length > 0 &&
              options?.map((option, i) =>
                <div key={option.id} className="active-images" onClick={() => handleClick(option)} >
                  <Avatar errorImg="/images/noimage.svg" src={option?.src} variant={'offer-image'} />
                </div>)}
          </Slider>
        </div>)

      case "news-and-offers-medium":
        return (< div className="news-and-offers-medium container-negative p-2" id="news-and-offers-large">
          <Slider {...newsAndOffersMedium}>
            {options?.length > 0 &&
              options?.map((option, i) =>
                <div key={option.id} className="active-images" onClick={() => handleClick(option)} >
                  <Avatar errorImg="/images/noimage.svg" src={option?.src} variant={'offer-image'} />
                </div>)}
          </Slider>
        </div>)

      case 'promotion-details':
        return (
          <>
            {options?.length > 0 && <Slider {...promotionSettings} className="promo-details-card p-2">
              {options?.length > 0 ?
                options?.map((option) =>
                  <div key={option?.id} className="active-images">
                    <Avatar src={option?.image} errorImage="/images/noimage.svg" variant={"product-details"} />
                  </div>) :
                <div className="active-images">
                  <Avatar src="/images/noimage.svg" variant={"product-details"} />
                </div>
              }
            </Slider>}
          </>)


      default:
        return (<div className="carousel position-relative">
          <div className="dots-container d-flex flex-column position-absolute gap-1">
            {options?.length > 0 &&
              options.map((_, i) => <div key={i} className={`${i === activeIndex ? 'active' : ''}`} onClick={() => selectImage(i)} />)}
          </div>
          <Slider {...settings}>
            {options?.length > 0 ?
              options.map((option) =>
                <div key={option.id} className="active-images">

                  <Avatar errorImg="/images/noimage.svg" src={option.src} onClick={() => handleZoomImage(option.src)} variant={'product-detail-image'} />
                </div>) : <Avatar src={"/images/noimage.svg"} variant={'product-detail-image'} />}
          </Slider>
        </div>)
    }


  }

  return (
    <>{renderCarousal()}</>
  );
};


